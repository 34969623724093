<template>
  <WeModal title="Rakip Ekle" icon="fas fa-code-branch" v-on:close="onClose">
    <div slot="body">
      <ul class="list-group mb-3">
        <li class="list-group-item bg-light">
          <b>ÜRÜN :</b> {{ getProductName }}
        </li>
      </ul>

      <!-- URL -->
      <WeInput
        prepend="Ürün Adresi"
        v-model="url"
        v-bind:error="$v.url.$error"
      />
      <!-- ./URL -->

      <!-- Competitor Name -->
      <WeInput prepend="Etiket (Opsiyonel)" v-model="tag" />
      <!-- ./Competitor Name -->

      <div class="text-center">
        <WeSubmitButton v-bind:submit-status="submit" v-on:submit="onSubmit" />
      </div>
    </div>
  </WeModal>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
export default {
  name: "AddCompetitor",
  props: {
    product: {
      default: null,
    },
  },
  validations: {
    url: {
      required,
    },
  },
  data() {
    return {
      loading: true,
      tag: null,
      url: null,
      submit: false,
    };
  },
  methods: {
    ...mapActions("priceAnalysis", [
      "searchCompetitor",
      "createProductCompetitor",
    ]),
    clearData() {
      this.loading = true;
      this.tag = null;
      this.url = null;
      this.submit = false;
    },
    onClose(data = null) {
      this.clearData();
      this.$emit("close", data);
    },
    onSubmit() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submit = true;
        const requestData = {};
        requestData.productId = this.product.id;
        requestData.url = this.url;
        if (this.tag) {
          requestData.tag = this.tag;
        }

        this.createProductCompetitor({
          requestData,
          onSuccess: (result) => {
            if (result && result.data && result.data.id) {
              const responseData = {};
              responseData.favicon = helper.getFavicon(this.url);
              responseData.name = result.data.name; // Domain
              responseData.url = this.url;
              responseData.productId = this.product.id;
              responseData.productCompetitorId = result.data.id;
              responseData.updatedAt = helper.timestampToDate(
                new Date().getTime()
              );

              if (this.tag) {
                responseData.tag = this.tag;
              }

              this.onClose(responseData);
              this.$toast.success("Rakip Başarıyla Eklendi");
            }
          },
          onError: (err) => {
            this.$toast.error("Rakip Eklenemedi !");
          },
          onFinish: () => {
            this.submit = false;
          },
        });
      } else {
        this.$toast.error("Lütfen zorunlu alanları doldurun");
      }
    },
  },
  computed: {
    getProductName() {
      return helper.toText(this.product.name);
    },
  },
};
</script>
<style lang="scss">
iframe {
  border: none;
}
th:first-child,
td:first-child {
  position: sticky;
  left: 0px;
}
</style>